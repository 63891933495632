// Custom colors
:root {
  --ion-color-wf-secondary: #EF8B55;
  --ion-color-wf-secondary-rgb: 239, 139, 85;
  --ion-color-wf-secondary-contrast: #fff;
  --ion-color-wf-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-wf-secondary-shade: #d27a4b;
  --ion-color-wf-secondary-tint: #f19766;
}

.ion-color-wf-secondary {
  --ion-color-base: var(--ion-color-wf-secondary);
  --ion-color-base-rgb: var(--ion-color-wf-secondary-rgb);
  --ion-color-contrast: var(--ion-color-wf-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-wf-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-wf-secondary-shade);
  --ion-color-tint: var(--ion-color-wf-secondary-tint);
}

:root {
  --ion-color-wf-primary: linear-gradient(139.45deg, #2458A6 8.13%, #4AB9B9 92.23%);
  --ion-color-wf-primary-rgb: 36, 88, 166;
  --ion-color-wf-primary-contrast: #ffffff;
  --ion-color-wf-primary-contrast-rgb: 255, 255, 255;
  --ion-color-wf-primary-shade: linear-gradient(139.45deg, #2458A6 8.13%, #4AB9B9 92.23%);
  --ion-color-wf-primary-tint: linear-gradient(139.45deg, #2458A6 8.13%, #4AB9B9 92.23%);
}

.ion-color-wf-primary {
  --ion-color-base: var(--ion-color-wf-primary);
  --ion-color-base-rgb: var(--ion-color-wf-primary-rgb);
  --ion-color-contrast: var(--ion-color-wf-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-wf-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-wf-primary-shade);
  --ion-color-tint: var(--ion-color-wf-primary-tint);
}

:root {
	--ion-color-light-gray: #5f5f5f;
	--ion-color-light-gray-rgb: 95,95,95;
	--ion-color-light-gray-contrast: #ffffff;
	--ion-color-light-gray-contrast-rgb: 255,255,255;
	--ion-color-light-gray-shade: #545454;
	--ion-color-light-gray-tint: #6f6f6f;
}

.ion-color-light-gray {
	--ion-color-base: var(--ion-color-light-gray);
	--ion-color-base-rgb: var(--ion-color-light-gray-rgb);
	--ion-color-contrast: var(--ion-color-light-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-light-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-light-gray-shade);
	--ion-color-tint: var(--ion-color-light-gray-tint);
}