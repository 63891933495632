@import "@ionic/angular/css/core.css";
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Outfit:wght@100..900&display=swap');

/* Swiper slider */
@import 'swiper/css';

* {
  font-family: "Outfit", serif;
}

ion-item,
ion-input,
ion-datetime-button {
  flex: 1;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

ion-content {
  overflow: visible !important;
}

ion-title {
  flex: 1;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

ion-button {
  font-size: 16px !important;
  font-weight: 600;
}


.auto-modal {
  --height: auto;
  --border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.lateral-modal {
  --height: 100%;
  --border-radius: 0px !important;
  display: flex;
  justify-content: flex-end;
  --max-width: 400px;
}

.mobile-only {
  display: none !important;
  visibility: hidden !important;
}

@media (max-width: 767px) {
  .lateral-modal {
    --max-width: 70%;
  }

  .desktop-only {
    display: none !important;
    visibility: hidden !important;
  }

  .mobile-only {
    display: inherit !important;
    visibility: visible !important;
  }
}


.select-wrapper-inner {
  width: 100%;
  justify-content: space-between;
  padding-right: 15px;
}